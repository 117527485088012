import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Spinner from 'components/atoms/Spinner/Spinner';
import { authCheckState } from './store/reducers/auth/auth.actions';
import { updateResponsive } from './store/reducers/responsive/responsive.actions';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ACTION_CLIENT, AUTH_BY } from './variables/auth';
import {
  createNotification,
  updateOpenNotification
} from './store/reducers/notification/notification.actions';
import moment from 'moment';
import OneSignal from 'react-onesignal';

const HomeLayout = lazy(() => import('./layouts/Home'));
const AuthLayout = lazy(() => import('./layouts/Auth'));
const WebviewLayout = lazy(() => import('./layouts/Webview'));
const TransisiLayout = lazy(() => import('./layouts/Transisi'));
const QuestionLayout = lazy(() => import('./layouts/Question'));
const EmptyLayout = lazy(() => import('./layouts/Empty'));

function App() {
  const auth = useSelector(state => state.auth);
  const token = auth.token || localStorage.getItem('token');
  const strategy = auth.strategy || localStorage.getItem('strategy');
  const action_client =
    auth.action_client || localStorage.getItem('action_client');
  const userType = auth.userType || localStorage.getItem('userType');
  const userId = auth.userId || localStorage.getItem('userId');
  const hasPassword =
    (auth.hasPassword || localStorage.getItem('hasPassword')) === 'true';
  const dispatch = useDispatch();

  let routes = (
    <Switch>
      <Route path="/home" component={HomeLayout} />
      <Route path="/artikel" component={EmptyLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/coming-soon" component={EmptyLayout} />
      <Redirect from="/profile" to="/auth" />
      <Redirect from="/notification" to="/auth" />
      <Redirect from="/catatan-kesehatan" to="/auth" />
      <Redirect from="/diet-dan-nutrisi" to="/auth" />
      <Redirect from="/riwayat-makanan" to="/auth" />
      <Redirect from="/pengingat-obat" to="/auth" />
      <Redirect from="/pengingat-obat/tambah-pengingat-obat" to="/auth" />
      <Redirect from="/seputar-diabetes" to="/auth" />
      <Redirect from="/statistik-pengingat-obat" to="/auth" />
      <Redirect from="/notification" to="/auth" />
      <Redirect from="/belanja-sehat" to="/coming-soon" />
      <Redirect from="/tanya-dokter" to="/auth" />
      <Redirect from="/" to="/home" />
    </Switch>
  );

  if (token) {
    if (
      strategy === AUTH_BY.socmed &&
      action_client === ACTION_CLIENT.sign_up
    ) {
      routes = (
        <Switch>
          <Route path="/home" component={HomeLayout} />
          <Route path="/artikel" component={EmptyLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/coming-soon" component={EmptyLayout} />
          <Route path="/profile" component={EmptyLayout} />
          <Redirect from="/notification" to="/auth" />
          <Redirect from="/catatan-kesehatan" to="/auth" />
          <Redirect from="/diet-dan-nutrisi" to="/auth" />
          <Redirect from="/riwayat-makanan" to="/auth" />
          <Redirect from="/pengingat-obat" to="/auth" />
          <Redirect from="/pengingat-obat/tambah-pengingat-obat" to="/auth" />
          <Redirect from="/seputar-diabetes" to="/auth" />
          <Redirect from="/statistik-pengingat-obat" to="/auth" />
          <Redirect from="/notification" to="/auth" />
          <Redirect from="/belanja-sehat" to="/coming-soon" />
          <Redirect from="/tanya-dokter" to="/auth" />
          <Redirect from="/profile" to="/profile/make-new-password" />
          <Redirect from="/" to="/home" />
        </Switch>
      );
    } else {
      if (userType !== null && userType !== 'null' && userType !== '') {
        routes = (
          <Switch>
            <Route path="/home" component={HomeLayout} />
            <Route path="/artikel" component={EmptyLayout} />
            <Route path="/catatan-kesehatan" component={EmptyLayout} />
            <Route path="/diet-dan-nutrisi" component={EmptyLayout} />
            <Route path="/riwayat-makanan" component={EmptyLayout} />
            <Route path="/pengingat-obat" component={EmptyLayout} />
            <Route
              path="/pengingat-obat/tambah-pengingat-obat"
              component={EmptyLayout}
            />
            <Route path="/statistik-pengingat-obat" component={EmptyLayout} />
            <Route path="/seputar-diabetes" component={EmptyLayout} />
            <Route path="/profile/edit" component={EmptyLayout} />
            <Route
              path="/profile/save-data-elvasense"
              component={EmptyLayout}
            />
            <Route path="/profile/change-password" component={EmptyLayout} />
            <Route path="/profile" component={EmptyLayout} />
            <Route path="/question" component={EmptyLayout} />
            <Route path="/notification" component={EmptyLayout} />
            <Route path="/page-information" component={EmptyLayout} />
            <Route path="/coming-soon" component={EmptyLayout} />
            <Route path="/belanja-sehat" component={EmptyLayout} />
            {/* <Redirect from="/belanja-sehat" to="/coming-soon" /> */}
            <Redirect from="/tanya-dokter" to="/coming-soon" />
            <Redirect from="/" to="/home" />
          </Switch>
        );
      } else {
        if (hasPassword) {
          routes = (
            <Switch>
              <Route path="/transisi" component={TransisiLayout} />
              <Redirect from="/" to="/transisi" />
            </Switch>
          );
        } else {
          routes = (
            <Switch>
              <Route path="/profile" component={EmptyLayout} />
              <Redirect from="*" to="/profile/make-new-password" />
            </Switch>
          );
        }
      }
    }
  }

  //#region Responsive
  const isDesktopOrLaptop = useMediaQuery({
    minDeviceWidth: 1224
  });
  const isMobile = useMediaQuery({
    maxWidth: 481
  });
  const isTablet = useMediaQuery({
    minWidth: 482,
    maxWidth: 1224
  });
  //#endregion

  useEffect(() => {
    dispatch(authCheckState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      updateResponsive({
        isDesktopOrLaptop,
        isMobile,
        isTablet
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop, isMobile, isTablet]);

  const onCreateNotification = event => {
    if (userId) {
      const n = event?.notification;
      const ad = n?.additionalData;
      dispatch(
        createNotification.call({
          oneSignalNotificationId: n?.notificationId,
          userId,
          date: moment(ad?.publish_date),
          type: ad?.type,
          title: ad?.title,
          description: ad?.description,
          status: 'unread',
          webUrl: ad?.webUrl,
          appUrl: ad?.appUrl,
          bannerId: ad?.bannerId,
          linkId: ad?.linkId,
          image: ad?.image
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const onUpdateNotification = event => {
    if (userId) {
      const id = event?.notification?.notificationId;
      dispatch(updateOpenNotification.call(id));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_ID,
      safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
      allowLocalhostAsSecureOrigin: true
      // notifyButton: {
      //   enable: true
      // }
      // webhooks: {
      //   cors: false, // Defaults to false if omitted
      //   'notification.displayed': process.env.REACT_APP_ONESIGNAL_WEBHOOK_URL,
      //   'notification.clicked': process.env.REACT_APP_ONESIGNAL_WEBHOOK_URL
      //   // ... follow the same format for any event in the list above
      // }
    }).then(() => {
      OneSignal.Slidedown.promptPush();
      // do other stuff
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    OneSignal.Notifications.addEventListener('click', event => {
      console.log('The notification was clicked!', event);
      onUpdateNotification(event);
    });
    OneSignal.Notifications.addEventListener('foregroundWillDisplay', event => {
      console.log('The notification was foregroundWillDisplay!', event);
      onCreateNotification(event);
    });
    OneSignal.Notifications.addEventListener('dismiss', event => {
      console.log('The notification was dismiss!', event, 'No Action');
    });

    OneSignal.User.PushSubscription.addEventListener(
      'change',
      pushSubscriptionChangeListener
    );
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      let permission = OneSignal.Notifications.permission; // boolean
      console.log('permission', permission);
      if (!permission) {
        console.log(`----------->> request permission`);
        OneSignal.Notifications.requestPermission();
      }

      const cekSudahLogin = OneSignal.User.PushSubscription.optedIn;

      console.log(
        'OneSignal.User.PushSubscription.id',
        OneSignal.User.PushSubscription.id
      );
      console.log(
        'OneSignal.User.PushSubscription.token',
        OneSignal.User.PushSubscription.token
      );
      console.log('cekSudahLogin', cekSudahLogin);

      if (permission && !cekSudahLogin && userId) {
        console.log(`----------->> subscription berhasil login`);
        await OneSignal.User.PushSubscription.optIn();
      }
      // if (cekSudahLogin && !userId) {
      //   console.log(`----------->> subscription berhasil logout`,)
      //   await OneSignal.User.PushSubscription.optOut();
      // }

      const checkLoginKedua = OneSignal.User.PushSubscription.optedIn;
      if (permission && checkLoginKedua && userId) {
        console.log(`----------->> subscription update external_id: ${userId}`);
        OneSignal.login(userId);
      }

      // if (userId) {
      //   console.log('userId', userId)
      //   const logint = await OneSignal.login(userId);
      //   console.log('logint', logint)
      // }
    }, 5000);
  }, [auth.userId]);

  function pushSubscriptionChangeListener(event) {
    console.log(`pushSubscriptionChangeListener!`);
    if (event.current.token) {
      console.log(`The push subscription has received a token!`);
      //this is a good place to call OneSignal.login and pass in your user ID
      // if (userId) {
      //   OneSignal.login(userId);
      // }
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>{routes}</Suspense>
    </BrowserRouter>
  );
}

export default App;
